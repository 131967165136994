import { createContext, PropsWithChildren, useContext } from "react";
import { IRootStore, RootStore } from "./RootStore";

const rootStore = new RootStore();
const StoreContext = createContext<IRootStore>(rootStore);
export const StoresProvider = ({ children }: PropsWithChildren<unknown>) => {
  return (
    <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
  );
};

export const useRootStore = () => useContext<IRootStore>(StoreContext);
