import {
  SiteComponentArgs,
  SiteComponentStateArgs,
  SiteComponentVersionArgs,
  SiteDatabaseCommandArgs
} from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import { DateTime } from "@bps/utils";
import { UseMutationResult } from "react-query";

export enum DeploymentAction {
  SetDesiredVersion = "setDesiredVersion",
  AgentDownloadDesiredVersion = "agentDownloadDesiredVersion",
  AgentInstallDesiredVersion = "agentInstallDesiredVersion",
  AgentUpdateDesiredVersion = "agentUpdateDesiredVersion",
  ManagerCheckUpdate = "managerCheckUpdate",
  InstallUpdateProsDb = "installUpdateProsDB",
  EnableProsDb = "enableProsDB",
  SendPing = "sendPing"
}

export enum DeploymentStatus {
  Pending = "pending",
  InProgress = "inProgress",
  Complete = "complete",
  Cancelled = "cancelled",
  Error = "error"
}

export enum DeploymentLogLevel {
  Info = "info",
  Warning = "warning",
  Error = "error"
}

export interface DeploymentLog {
  level: DeploymentLogLevel;
  text: string;
}

export interface DeploymentData {
  [DeploymentAction.SetDesiredVersion]: SiteComponentVersionArgs;
  [DeploymentAction.AgentDownloadDesiredVersion]: SiteComponentStateArgs;
  [DeploymentAction.AgentInstallDesiredVersion]: SiteComponentStateArgs;
  [DeploymentAction.AgentUpdateDesiredVersion]: SiteComponentStateArgs;
  [DeploymentAction.ManagerCheckUpdate]: string;
  [DeploymentAction.InstallUpdateProsDb]: SiteDatabaseCommandArgs;
  [DeploymentAction.EnableProsDb]: SiteDatabaseCommandArgs;
  [DeploymentAction.SendPing]: SiteComponentArgs;
}

export interface Deployment<T extends DeploymentAction = DeploymentAction> {
  id: string;
  action: T;
  data: DeploymentData[T];
  startTime?: DateTime;
  endTime?: DateTime;
  status: DeploymentStatus;
  mutation?: UseMutationResult;
  error?: string;
}
