import { config } from "@libs/config/config";
import { DeploymentStore } from "./deployment-store/DeploymentStore";
import { FeedbackStore } from "./FeedbackStore";

export interface IRootStore {
  feedback: FeedbackStore;
  deployment: DeploymentStore;
}

export class RootStore implements IRootStore {
  constructor() {
    this.feedback = new FeedbackStore({
      notificationDuration: config.notificationDuration
    });

    this.deployment = new DeploymentStore();
  }

  public readonly feedback: FeedbackStore;
  public readonly deployment: DeploymentStore;
}
