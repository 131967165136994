import { computed, makeObservable, observable, runInAction } from "mobx";
import { UseMutationResult } from "react-query";

import { DATE_FORMATS, DateTime, newGuid } from "@bps/utils";
import { SiteDatabaseCommandArgs } from "@libs/api/gateways/sia/sia-ops-gateway.hooks";

import { Tenant } from "@libs/api/gateways/plt/plt-gateway.dtos";
import {
  Deployment,
  DeploymentAction,
  DeploymentData,
  DeploymentLog,
  DeploymentLogLevel,
  DeploymentStatus
} from "./deployment-store.types";

export class DeploymentStore {
  constructor() {
    makeObservable(this, {
      progressCompleted: computed,
      progressTotal: computed,
      progressPercentage: computed,
      isDeploying: computed
    });
  }

  public logs = observable<DeploymentLog>([], { deep: false });

  public deployments = observable.map<string, Deployment>({}, { deep: true });
  public selectedTenants = observable<Tenant>([], { deep: true });

  public setSelectedTenents = (tenants: Tenant[]) => {
    runInAction(() => {
      this.selectedTenants.replace(tenants);
    });
  };

  public clearDeployments = () => {
    runInAction(() => {
      this.deployments.clear();
    });
  };

  public addDeployment = async <T extends DeploymentAction>(
    action: T,
    mutation: UseMutationResult,
    data: DeploymentData[T]
  ) => {
    const id = newGuid();
    const deployment: Deployment<T> = {
      id,
      action,
      data,
      status: DeploymentStatus.Pending,
      mutation,
      startTime: DateTime.now()
    };

    runInAction(() => {
      this.deployments.set(id, deployment);
    });

    const endTime = DateTime.now();
    const updated: Deployment = {
      ...deployment,
      endTime
    };

    try {
      this.createStartLog(updated);
      const mutateResult = await mutation.mutateAsync(data);
      this.createMutationLog(mutateResult, endTime);
      updated.status = DeploymentStatus.Complete;
    } catch (e) {
      updated.error = String(e);
      updated.status = DeploymentStatus.Error;
    } finally {
      runInAction(() => {
        this.updateDeployment(id, updated);
        this.createEndLog(updated);
      });
    }
  };

  private createMutationLog = (mutateResult: unknown, endTime: DateTime) => {
    if (!mutateResult) return;

    let result = "";
    if (typeof mutateResult === "object") {
      result = JSON.stringify(mutateResult);
    } else {
      result = `${mutateResult}`;
    }

    const level =
      result.includes("Error") || result.includes("BadRequest")
        ? DeploymentLogLevel.Error
        : DeploymentLogLevel.Info;

    const timestamp = `> [${endTime.toFormat(
      DATE_FORMATS.DETAILED_DATE_TIME
    )}]`;

    const message = `${timestamp} Output: ${result}`;
    this.addLog({
      level,
      text: message
    });
  };

  public createEndLog = <T extends DeploymentAction>(
    deployment: Deployment<T>
  ) => {
    const { endTime } = deployment;

    if (!endTime) {
      return;
    }
    let text = "Unknown";
    const level =
      deployment.status === DeploymentStatus.Error
        ? DeploymentLogLevel.Error
        : DeploymentLogLevel.Info;
    let updateText = "";
    let tenantId = "";
    let prosDbArgs: SiteDatabaseCommandArgs;

    const timestamp = `> [${endTime.toFormat(
      DATE_FORMATS.DETAILED_DATE_TIME
    )}]`;

    switch (deployment.action) {
      case DeploymentAction.SetDesiredVersion:
        const desiredData = deployment.data as DeploymentData[DeploymentAction.SetDesiredVersion];
        updateText = `${
          desiredData.component
        } desired version to ${desiredData.version || ""} for tenant ${
          desiredData.siteId
        }`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error updating ${updateText} (${deployment.error})`;
        } else {
          text = `${timestamp} Sucessfully updated ${updateText}`;
        }
        break;

      case DeploymentAction.AgentDownloadDesiredVersion:
        const downloadData = deployment.data as DeploymentData[DeploymentAction.AgentDownloadDesiredVersion];
        updateText = `${downloadData.componentType} for tenant ${downloadData.siteId}`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error updating component: ${updateText}. Last known state is: ${downloadData.state} (${deployment.error})`;
        } else {
          text = `${timestamp} Sucessfully downloaded ${updateText}`;
        }
        break;

      case DeploymentAction.AgentInstallDesiredVersion:
        const installData = deployment.data as DeploymentData[DeploymentAction.AgentInstallDesiredVersion];
        updateText = `${installData.componentType} for tenant ${installData.siteId}`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error updating component: ${updateText}. Last known state is: ${installData.state} (${deployment.error})`;
        } else {
          text = `${timestamp} Sucessfully installed ${updateText}`;
        }
        break;

      case DeploymentAction.AgentUpdateDesiredVersion:
        const updateData = deployment.data as DeploymentData[DeploymentAction.AgentUpdateDesiredVersion];
        updateText = `${updateData.componentType} for tenant ${updateData.siteId}`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error updating component: ${updateText}. Last known state is: ${updateData.state} (${deployment.error})`;
        } else {
          text = `${timestamp} Sucessfully updated ${updateText}`;
        }
        break;

      case DeploymentAction.ManagerCheckUpdate:
        tenantId = deployment.data as DeploymentData[DeploymentAction.ManagerCheckUpdate];
        updateText = `site manager update on tenant ${tenantId}`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error when checking for ${updateText}. (${deployment.error})`;
        } else {
          text = `${timestamp} Sucessfully checked for ${updateText}`;
        }
        break;

      case DeploymentAction.InstallUpdateProsDb:
        prosDbArgs = deployment.data as DeploymentData[DeploymentAction.InstallUpdateProsDb];
        updateText = `Installing/Updating PrOS database for tenant ${prosDbArgs.siteId}`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error when ${updateText}. (${deployment.error})`;
        } else {
          text = `${timestamp} Completed: ${updateText}`;
        }
        break;

      case DeploymentAction.EnableProsDb:
        prosDbArgs = deployment.data as DeploymentData[DeploymentAction.EnableProsDb];
        updateText = `Enabling PrOS database for tenant ${prosDbArgs.siteId}`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error when ${updateText}. (${deployment.error})`;
        } else {
          text = `${timestamp} Completed: ${updateText}`;
        }
        break;

      case DeploymentAction.SendPing:
        const pingArgs = deployment.data as DeploymentData[DeploymentAction.SendPing];
        updateText = `Sending ping to ${pingArgs.componentType} with tenantId ${pingArgs.siteId}`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error when ${updateText}. (${deployment.error})`;
        } else {
          text = `${timestamp} Completed: ${updateText}`;
        }
        break;
    }

    this.addLog({
      level,
      text
    });
  };

  public createStartLog = <T extends DeploymentAction>(
    deployment: Deployment<T>
  ) => {
    const { endTime } = deployment;

    if (!endTime) {
      return;
    }

    let text = "Unknown";
    const level =
      deployment.status === DeploymentStatus.Error
        ? DeploymentLogLevel.Error
        : DeploymentLogLevel.Info;
    let updateText = "";
    let tenantId = "";
    let prosDbArgs: SiteDatabaseCommandArgs;

    const timestamp = `> [${endTime.toFormat(
      DATE_FORMATS.DETAILED_DATE_TIME
    )}]`;
    switch (deployment.action) {
      case DeploymentAction.SetDesiredVersion:
        const desiredData = deployment.data as DeploymentData[DeploymentAction.SetDesiredVersion];
        updateText = `${
          desiredData.component
        } desired version to ${desiredData.version || ""} for tenant ${
          desiredData.siteId
        }`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error updating ${updateText} (${deployment.error})`;
        } else {
          text = `${timestamp} Updated ${updateText}`;
        }
        break;

      case DeploymentAction.AgentDownloadDesiredVersion:
        const downloadData = deployment.data as DeploymentData[DeploymentAction.AgentDownloadDesiredVersion];
        updateText = `${downloadData.componentType} for tenant ${downloadData.siteId}`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error updating component: ${updateText}. Last known state is: ${downloadData.state} (${deployment.error})`;
        } else {
          text = `${timestamp} Downloading ${updateText}...`;
        }
        break;

      case DeploymentAction.AgentInstallDesiredVersion:
        const installData = deployment.data as DeploymentData[DeploymentAction.AgentInstallDesiredVersion];
        updateText = `${installData.componentType} for tenant ${installData.siteId}`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error updating component: ${updateText}. Last known state is: ${installData.state} (${deployment.error})`;
        } else {
          text = `${timestamp} Installing ${updateText}...`;
        }
        break;

      case DeploymentAction.AgentUpdateDesiredVersion:
        const updateData = deployment.data as DeploymentData[DeploymentAction.AgentUpdateDesiredVersion];
        updateText = `${updateData.componentType} for tenant ${updateData.siteId}`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error updating component: ${updateText}. Last known state is: ${updateData.state} (${deployment.error})`;
        } else {
          text = `${timestamp} Updating ${updateText}...`;
        }
        break;

      case DeploymentAction.ManagerCheckUpdate:
        tenantId = deployment.data as DeploymentData[DeploymentAction.ManagerCheckUpdate];
        updateText = `Checking for site manager update on tenant ${tenantId}`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error when ${updateText}. (${deployment.error})`;
        } else {
          text = `${timestamp} ${updateText}`;
        }
        break;

      case DeploymentAction.InstallUpdateProsDb:
        prosDbArgs = deployment.data as DeploymentData[DeploymentAction.InstallUpdateProsDb];
        updateText = `Installing/Updating PrOS database for tenant ${prosDbArgs.siteId}`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error when ${updateText}. (${deployment.error})`;
        } else {
          text = `${timestamp} ${updateText}`;
        }
        break;

      case DeploymentAction.EnableProsDb:
        prosDbArgs = deployment.data as DeploymentData[DeploymentAction.EnableProsDb];
        updateText = `Enabling PrOS database for tenant ${prosDbArgs.siteId}`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error when ${updateText}. (${deployment.error})`;
        } else {
          text = `${timestamp} ${updateText}`;
        }
        break;

      case DeploymentAction.SendPing:
        const pingArgs = deployment.data as DeploymentData[DeploymentAction.SendPing];
        updateText = `Sending ping to ${pingArgs.componentType} with tenantId ${pingArgs.siteId}`;

        if (deployment.status === DeploymentStatus.Error) {
          text = `${timestamp} Error when ${updateText}. (${deployment.error})`;
        } else {
          text = `${timestamp} ${updateText}`;
        }
        break;
    }

    this.addLog({
      level,
      text
    });
  };

  public addLog = (log: DeploymentLog) => {
    runInAction(() => {
      this.logs.push(log);
    });
  };

  public setLogs = (logs: DeploymentLog[]) => {
    runInAction(() => {
      this.logs.replace(logs);
    });
  };

  public updateDeployment = (id: string, updated: Partial<Deployment>) => {
    runInAction(() => {
      const deployment = this.deployments.get(id);
      if (!deployment) {
        return;
      }
      this.deployments.set(id, { ...deployment, ...updated });
    });
  };

  get pendingDeployments() {
    return this.getDeploymentsByStatus(DeploymentStatus.Pending);
  }

  get isDeploying() {
    return this.pendingDeployments.length > 0;
  }

  get progressTotal() {
    return this.deployments.size;
  }

  get progressCompleted() {
    return this.getDeploymentsByStatus(DeploymentStatus.Complete).length;
  }

  get progressPercentage() {
    return this.progressCompleted > 0 && this.progressTotal > 0
      ? this.progressCompleted / this.progressTotal
      : 0;
  }

  public getDeploymentsByStatus = (
    status: DeploymentStatus | DeploymentStatus[]
  ) => {
    const _deployments: Deployment[] = [];
    this.deployments.forEach(deployment => {
      if (Array.isArray(status)) {
        if (status.includes(deployment.status)) {
          _deployments.push(deployment);
        }
      } else {
        if (deployment.status === status) {
          _deployments.push(deployment);
        }
      }
    });
    return _deployments;
  };
}
